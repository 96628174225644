import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../components/de/layout"
//import Image from "../components/image"
import SEO from "../../components/de/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

import product5 from "./../../images/moonamar-sheabutterseife-mit-schwarzkummelol-vit-e.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="MOONAMAR - SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
      description="MOONAMAR Sheabutterseife - milde und hautfreundliche Reinigung, geeignet für die empfindliche und zu Allergien neigende Haut."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product5}
                alt="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
                title="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
              />
              <div className="_info">
                <div className="_inner">
                  <span>MOONAMAR</span>
                  <div className="title">
                    Sheabutterseife mit Schwarzkümmelöl
                  </div>
                  <div className="subtitle">Verpackungsgröße: 100 g</div>
                  <div className="price">2,50 €</div>
                </div>
                <div className="_buttonShop">
                  <Link to="/bestellformular">ZUR BESTELLUNG</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Produktbeschreibung</Tab>
                <Tab>Zutaten und Verwendung</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    MOONAMAR Seife ist eine besonders hautfreundliche Seife auf
                    Basis von Sheabutter, Vitamin E und Schwarzkümmelöl, das für
                    seine beruhigenden und regenerierenden Eigenschaften bekannt
                    ist.
                  </p>
                  <p>
                    <strong>- besonders hautfreundlich</strong>
                    <br />
                    <strong>- mit entzündungshemmendem Schwarzkümmelöl</strong>
                    <br />
                    <strong>
                      - geeignet für Hände, Gesicht, Körper und schuppige
                      Kopfhaut
                    </strong>
                    <br />
                    <strong>
                      - Kann auch bei Neurodermitis, Schuppenflechte oder Akne
                      angewendet werden
                    </strong>
                    <br />
                    <strong>- vegan</strong>
                  </p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>Inhaltsstoffe:</h3>
                  <p>
                    Sodium Palmate, Sodium Palm Kernelate, Aqua, Butyrospermum
                    Parkii (Sheabutter), Nigella Sativa Seed Oil
                    (Schwarzkümmelöl), Glicerine, Sodium Chloride, Citric Acid,
                    Tocopherol (Vitamin E).
                  </p>
                  <h3>Anwendung:</h3>
                  <p>
                    MOONAMAR Seife ist zur Reinigung und Pflege von trockener,
                    empfindlicher oder gereizter Haut empfohlen. Die Seife kann
                    für Hände, Gesicht und Körper verwendet werden. Sie eignet
                    sich auch hervorragend als Rasierseife, gerade bei sensibler
                    Haut.
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default CorokotovoUlje
